import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addDoc,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { toast } from 'sonner';
import { IProgramAction } from '@/types';
import { db } from '@/utils/firebase/firebaseInit';
import { fetchCurrentProgramWeek } from './weekProgramSlice';
import { FocusArea } from '@fitmate-coach/fitmate-types';

interface IProgramActionsInterface {
  loading: boolean;
  programAction?: any;
  programActions?: any;
  programActionStatus?: string;
  socialSupportGoalActive: boolean;
  socialSupportGoalCompleted: boolean;
  currentWeekProgramActions?: IProgramAction[];
}

const initialState: IProgramActionsInterface = {
  loading: true,
  socialSupportGoalCompleted: false,
  socialSupportGoalActive: false,
};

export const programActionDocRef = (userId: string, actionId: string) =>
  doc(db, 'users', userId, 'programActions', actionId);

export const programActionsCollectionRef = (userId: string) =>
  collection(db, 'users', userId, 'programActions');

export const fetchProgramActionById = createAsyncThunk(
  'programActions/fetchBodyGoals',
  async (args: any, APIThunk) => {
    console.log('inside fetchProgramActionById');
    try {
      const { userId, actionId } = args;
      const docSnap = await getDoc(programActionDocRef(userId, actionId));
      if (!docSnap.exists()) {
        toast.error('Program Action not found');
        return;
      }
      APIThunk.dispatch(fetchCurrentProgramWeek({ userId, currentPlanId: docSnap.data().planId }));
      return { id: docSnap.id, ...docSnap.data() };
    } catch (e) {
      console.log('error', e);
    }
  },
);

export const listProgramActions = createAsyncThunk(
  'programActions/listProgramActions',
  async (userId: string, APIThunk) => {
    const { dispatch } = APIThunk;
    try {
      const querySnap = await getDocs(programActionsCollectionRef(userId));
      return querySnap.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
    } catch (e) {
      console.log('error', e);
    }
  },
);

export const getCurrentWeekProgramActions = createAsyncThunk(
  'programActions/getCurrentWeekProgramActions',
  async (args: { userId: string; planId: string }, APIThunk) => {
    const { dispatch } = APIThunk;
    try {
      const { userId, planId } = args;
      const ref = programActionsCollectionRef(userId);
      const q = query(ref, where('planId', '==', planId));
      const querySnap = await getDocs(q);
      const programActions = querySnap.docs.map((docSnap) => ({
        ...docSnap.data(),
        id: docSnap.id,
      }));
      for (const action of programActions) {
        const { published, lastTracking } = action as IProgramAction;
        if (published && lastTracking) {
          const { daysPerWeek } = published;
          const { currentDay } = lastTracking;
          if (currentDay >= daysPerWeek) {
            dispatch(socialSupportGoalAchieved());
          } else {
            dispatch(socialSupportGoalActivated());
          }
        }
      }
      return programActions;
    } catch (e) {
      console.log('error', e);
    }
  },
);

interface IAddProgramAction {
  userId: string;
  activePlanId: string;
  areaId: string;
  actionToAdd: IProgramAction;
  currentFocusArea: FocusArea;
}

export const addProgramAction = createAsyncThunk(
  'programActions/addProgramAction',
  async (args: IAddProgramAction, APIThunk) => {
    try {
      const { dispatch } = APIThunk;
      const { userId, activePlanId, areaId, actionToAdd, currentFocusArea } = args;
      const draft = actionToAdd.draft;
      const newAction = {
        ...actionToAdd,
        planId: activePlanId,
        planAreaId: areaId,
        assignmentTime: Timestamp.now(),
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        canBeBadged: currentFocusArea.canBeBadged,
        focusAreaId: currentFocusArea.focusAreaId,
        focusAreaCategory: currentFocusArea.focusAreaCategory,
        defaultShareRaf: currentFocusArea.defaultShareRaf,
        defaultShareSocial: currentFocusArea.defaultShareSocial,
        iconUrl: currentFocusArea.iconUrl,
        draft: {
          ...draft,
          isNew: true,
          isDeleted: false,
        },
      };

      const docRef = await addDoc(programActionsCollectionRef(userId), newAction);

      await dispatch(listProgramActions(userId)).unwrap();

      return { id: docRef.id, ...newAction };
    } catch (e) {
      console.log('error', e);
      throw e;
    }
  },
);

interface IAddProgramActions {
  userId: string;
  activePlanId: string;
  planToUpdateId: string;
}

export const addProgramActions = createAsyncThunk(
  'programActions/addProgramActions',
  async (args: IAddProgramActions, APIThunk) => {
    try {
      const { dispatch } = APIThunk;
      const { userId, activePlanId, planToUpdateId } = args;
      const activePlanActions = await getDocs(
        query(programActionsCollectionRef(userId), where('planId', '==', activePlanId)),
      );
      await Promise.all(
        activePlanActions.docs.map((action) => {
          const actionToDuplicate = action.data();
          let status = actionToDuplicate.published?.status;
          status = status === 'new' ? 'existing' : status;
          let newAction;
          if (actionToDuplicate.published) {
            newAction = {
              ...actionToDuplicate,
              planId: planToUpdateId,
              draft: {
                ...actionToDuplicate.published,
                isNew: false,
                status: status,
                scheduledDays: [],
              },
            };
            addDoc(programActionsCollectionRef(userId), newAction).then((addedDoc) => {
              updateDoc(addedDoc, {
                lastTracking: deleteField(),
                id: deleteField(),
              });
            });
          }
        }),
      );
      dispatch(listProgramActions(userId));
    } catch (e) {
      console.log('error', e);
    }
  },
);

export const updateProgramAction = createAsyncThunk(
  'programActions/updateProgramAction',
  async (
    args: { actionId: string; action: IProgramAction; planId: string; userId: string },
    APIThunk,
  ) => {
    try {
      const { dispatch } = APIThunk;
      const { actionId, action, planId, userId } = args;
      const isReset = action.isReset;
      delete action['isReset'];

      await updateDoc(programActionDocRef(userId, actionId), {
        ...action,
        planId,
        ...(isReset && { assignmentTime: Timestamp.now(), lastTracking: deleteField() }),
      });

      await dispatch(listProgramActions(userId)).unwrap();

      return { actionId, action, planId, userId };
    } catch (e) {
      console.log('error', e);
      throw e;
    }
  },
);

export const updateProgramActions = createAsyncThunk(
  'programActions/updateProgramActions',
  async (args: { userId: string; actions: IProgramAction[] }, APIThunk: any) => {
    try {
      const { userId, actions } = args;
      const { dispatch } = APIThunk;
      if (actions && userId) {
        await Promise.all(
          actions.map((action: IProgramAction) => {
            if (action.id) {
              const lastTracking =
                action.lastTracking && action.lastTracking.actionId === action.id
                  ? action.lastTracking
                  : undefined;
              updateDoc(programActionDocRef(userId, action.id), {
                ...action,
                published: {
                  ...(action.draft ?? action.published),
                },
                lastTracking: lastTracking ?? deleteField(),
                draft: deleteField(),
              });
            }
          }),
        );
        dispatch(listProgramActions(userId));
      }
    } catch (e) {
      console.log('error', e);
    }
  },
);

export const programActionsSlice = createSlice({
  name: 'programActions',
  initialState,
  reducers: {
    addProgramAction: (state, action: PayloadAction<any>) => {
      state.programAction = action.payload;
    },
    addProgramActions: (state, action: PayloadAction<any>) => {
      state.programAction = action.payload;
    },
    setProgramActionStatus: (state, action: PayloadAction<any>) => {
      state.programActionStatus = action.payload;
    },
    updateAction: (state, action: PayloadAction<any>) => {
      state.programAction = action.payload;
    },
    resetActions: (state) => {
      state.programAction = undefined;
      state.programActions = undefined;
      state.programActionStatus = undefined;
      state.socialSupportGoalActive = false;
      state.socialSupportGoalCompleted = false;
    },
    socialSupportGoalAchieved: (state) => {
      state.socialSupportGoalCompleted = true;
    },
    socialSupportGoalActivated: (state) => {
      state.socialSupportGoalActive = true;
    },
    loadProgramPlan: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProgramActionById.pending, (state) => {
        state.loading = true;
        state.programAction = undefined;
      })
      .addCase(fetchProgramActionById.fulfilled, (state, action) => {
        state.loading = false;
        state.programAction = action.payload;
      })
      .addCase(listProgramActions.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) state.programActions = action.payload;
      })
      .addCase(getCurrentWeekProgramActions.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) state.currentWeekProgramActions = action.payload as IProgramAction[];
      })
      .addCase(updateProgramAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProgramAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProgramAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addProgramAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProgramAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addProgramAction.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setProgramActionStatus,
  updateAction,
  resetActions,
  socialSupportGoalAchieved,
  socialSupportGoalActivated,
  loadProgramPlan,
} = programActionsSlice.actions;

export default programActionsSlice.reducer;
