import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '@/utils/firebase/firebaseInit';
import { Message } from '@fitmate-coach/fitmate-types';

export const getReminderAssociatedMessages = async (messageId: string) => {
  const reminderRef = collection(db, 'messages');
  const q = query(reminderRef, where('linkedReminderId', '==', messageId));
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as Message[];
};
