import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Switch, Select, MenuItem, FormControl, InputLabel, Checkbox } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { format } from 'date-fns';
import Button from '@/common/core/Button';
import {
  Title,
  TextAreaLabel,
  DatePickerContainer,
  SelectedDatesContainer,
  DateChip,
  RecurringContainer,
  RecurringLabel,
  TimeSelectContainer,
  MessageTextArea,
  ButtonsContainer,
} from '../ChatMessagesList/ChatMessagesListItemCreateCustomReminder/style';
import { ReminderSchedule } from '@/types/reminders';

interface SharedReminderFormProps {
  title: string;
  text: string;
  selectedDates: Date[];
  isRecurring: boolean;
  selectedTime: ReminderSchedule['nickname'];
  placeholder?: string;
  onTextChange: (text: string) => void;
  onDateChange: (date: Date | null) => void;
  onRemoveDate: (date: Date) => void;
  onRecurringChange: (isRecurring: boolean) => void;
  onTimeChange: (time: ReminderSchedule['nickname']) => void;
  buttons: React.ReactNode;
  frequency?: 'daily' | 'weekly' | 'monthly';
  onFrequencyChange?: (frequency: 'daily' | 'weekly' | 'monthly') => void;
  onEndDateChange?: (needEndDate: boolean) => void;
  needEndDate?: boolean;
  weeklyDays?: number[];
  onWeeklyDaysChange?: (days: number[]) => void;
}

export const SharedReminderForm = ({
  title,
  text,
  selectedDates,
  isRecurring,
  selectedTime,
  placeholder,
  onTextChange,
  onDateChange,
  onRemoveDate,
  onRecurringChange,
  onTimeChange,
  buttons,
  frequency = 'daily',
  onFrequencyChange,
  onEndDateChange,
  needEndDate,
  weeklyDays,
  onWeeklyDaysChange,
}: SharedReminderFormProps) => {
  const handleWeeklyDayChange = (day: number) => {
    const otherDays = weeklyDays?.filter((d) => d !== day) ?? [];
    const isSelected = weeklyDays?.includes(day);
  };
  return (
    <>
      <Title>{title}</Title>
      {/*<RecurringContainer>*/}
      {/*  <RecurringLabel>Recurring reminder</RecurringLabel>*/}
      {/*  <Switch*/}
      {/*    checked={isRecurring}*/}
      {/*    onChange={(e) => {*/}
      {/*      const newIsRecurring = e.target.checked;*/}
      {/*      onRecurringChange(newIsRecurring);*/}
      {/*    }}*/}
      {/*    size="small"*/}
      {/*  />*/}
      {/*</RecurringContainer>*/}

      {isRecurring && (
        <>
          <FormControl fullWidth size="small" sx={{ mt: 2, mb: 2 }}>
            <InputLabel>Frequency</InputLabel>
            <Select
              value={frequency}
              label="Frequency"
              onChange={(e) =>
                onFrequencyChange?.(e.target.value as 'daily' | 'weekly' | 'monthly')
              }
            >
              <MenuItem value="daily">Daily</MenuItem>
              {/*<MenuItem value="weekly">Weekly</MenuItem>*/}
              {/*<MenuItem value="monthly">Monthly</MenuItem>*/}
            </Select>
          </FormControl>
          {/*<div className="flex my-4">*/}
          {/*  <InputLabel>End date?</InputLabel>*/}
          {/*  <Switch*/}
          {/*    checked={needEndDate}*/}
          {/*    onChange={(e) => {*/}
          {/*      const newNeedEndDate = e.target.checked;*/}
          {/*      onEndDateChange(newNeedEndDate);*/}
          {/*    }}*/}
          {/*    size="small"*/}
          {/*  />*/}
          {/*</div>*/}
          {frequency === 'weekly' && (
            <div>
              <InputLabel>Repeat on:</InputLabel>
              <div className="flex">
                <Checkbox checked={weeklyDays?.includes(1)} />
                Mon
                <Checkbox checked={weeklyDays?.includes(2)} />
                Tue
                <Checkbox checked={weeklyDays?.includes(3)} />
                Wed
                <Checkbox checked={weeklyDays?.includes(4)} />
                Thu
                <Checkbox checked={weeklyDays?.includes(5)} />
                Fri
                <Checkbox checked={weeklyDays?.includes(6)} />
                Sat
                <Checkbox checked={weeklyDays?.includes(7)} />
                Sun
              </div>
            </div>
          )}
        </>
      )}

      <DatePickerContainer>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disabled={isRecurring && !needEndDate}
            label="Select dates"
            value={null}
            onChange={onDateChange}
            minDate={new Date()}
            slotProps={{
              textField: {
                fullWidth: true,
                size: 'small',
              },
            }}
          />
        </LocalizationProvider>
        <SelectedDatesContainer>
          {selectedDates.map((date) => (
            <DateChip key={date.toISOString()}>
              {format(date, 'MMM d, yyyy')}
              <CloseIcon
                sx={{ fontSize: 16, cursor: 'pointer' }}
                onClick={() => onRemoveDate(date)}
              />
            </DateChip>
          ))}
        </SelectedDatesContainer>
      </DatePickerContainer>

      <TimeSelectContainer>
        <FormControl fullWidth size="small">
          <InputLabel>Time</InputLabel>
          <Select
            value={selectedTime}
            label="Time"
            onChange={(e) => onTimeChange(e.target.value as ReminderSchedule['nickname'])}
          >
            <MenuItem value="morning">Morning</MenuItem>
            <MenuItem value="midday">Midday</MenuItem>
            <MenuItem value="afternoon">Afternoon</MenuItem>
            <MenuItem value="early_evening">Early Evening</MenuItem>
          </Select>
        </FormControl>
      </TimeSelectContainer>

      <TextAreaLabel>Reminder subject:</TextAreaLabel>
      <MessageTextArea
        value={text}
        placeholder={placeholder}
        onChange={(e) => onTextChange(e.target.value)}
        onClick={(e) => e.stopPropagation()}
      />

      <ButtonsContainer>{buttons}</ButtonsContainer>
    </>
  );
};
