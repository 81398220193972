import { useState } from 'react';
import { format } from 'date-fns';
import Button from '@/common/core/Button';
import { SharedReminderForm } from './SharedReminderForm';
import { ReminderSchedule } from '@/types/reminders';

interface ReminderFormProps {
  title: string;
  initialData?: {
    content: string;
    dates: Date[];
    isRecurring: boolean;
    schedule: {
      timeOfDay: ReminderSchedule['nickname'];
      frequency?: 'daily' | 'weekly' | 'monthly';
      needEndDate: boolean;
      weeklyDays?: number[];
    };
  };
  onSubmit: (data: {
    content: string;
    dates: Date[];
    isRecurring: boolean;
    schedule: {
      timeOfDay: ReminderSchedule['nickname'];
      frequency?: 'daily' | 'weekly' | 'monthly';
    };
  }) => void;
  onCancel: () => void;
  submitLabel: string;
  cancelLabel: string;
}

export const ReminderForm = ({
  title,
  initialData,
  onSubmit,
  onCancel,
  submitLabel,
  cancelLabel,
}: ReminderFormProps) => {
  const [content, setContent] = useState(initialData?.content ?? '');
  const [selectedDates, setSelectedDates] = useState<Date[]>(initialData?.dates ?? []);
  const [isRecurring, setIsRecurring] = useState(initialData?.isRecurring ?? false);
  const [needEndDate, setNeedEndDate] = useState(initialData?.schedule.needEndDate ?? false);
  const [selectedTime, setSelectedTime] = useState<ReminderSchedule['nickname']>(
    initialData?.schedule?.timeOfDay ?? 'morning',
  );
  const [frequency, setFrequency] = useState<'daily' | 'weekly' | 'monthly'>(
    initialData?.schedule?.frequency ?? 'daily',
  );
  const [weeklyDays, setWeeklyDays] = useState<number[]>(initialData?.schedule?.weeklyDays ?? []);

  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      if (isRecurring) {
        setSelectedDates([newDate]);
      } else {
        if (
          !selectedDates.some(
            (date) => format(date, 'yyyy-MM-dd') === format(newDate, 'yyyy-MM-dd'),
          )
        ) {
          setSelectedDates([...selectedDates, newDate]);
        }
      }
    }
  };

  const handleSubmit = () => {
    onSubmit({
      content,
      dates: selectedDates,
      isRecurring,
      schedule: {
        timeOfDay: selectedTime,
        ...(isRecurring && { frequency }),
      },
    });
  };

  const buttons = (
    <>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        {submitLabel}
      </Button>
      <Button variant="contained" color="error" onClick={onCancel}>
        {cancelLabel}
      </Button>
    </>
  );

  return (
    <SharedReminderForm
      title={title}
      text={content}
      selectedDates={selectedDates}
      isRecurring={isRecurring}
      selectedTime={selectedTime}
      onTextChange={setContent}
      onDateChange={handleDateChange}
      onRemoveDate={(date) =>
        setSelectedDates((dates) =>
          dates.filter((d) => format(d, 'yyyy-MM-dd') !== format(date, 'yyyy-MM-dd')),
        )
      }
      onRecurringChange={(newIsRecurring) => {
        setIsRecurring(newIsRecurring);
        if (newIsRecurring && selectedDates.length > 1) {
          setSelectedDates([selectedDates[0]]);
        }
      }}
      onTimeChange={setSelectedTime}
      buttons={buttons}
      frequency={frequency}
      onFrequencyChange={setFrequency}
      onEndDateChange={setNeedEndDate}
      needEndDate={needEndDate}
      weeklyDays={weeklyDays}
      onWeeklyDaysChange={setWeeklyDays}
    />
  );
};
