import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getDoc,
  getDocs,
  doc,
  updateDoc,
  collection,
  query,
  orderBy,
  Timestamp,
  deleteField,
} from 'firebase/firestore';
import { db } from '@/utils/firebase/firebaseInit';
import { listProgramActions, loadProgramPlan } from './programActionsSlice';
import { IUpdateWeekProgram, IWeekProgram, TFirePlanId, TFocusArea } from '@/types';
import { onSnapshot } from '@firebase/firestore';
import { toast } from 'sonner';
import { FocusArea, ProgramPlan } from '@fitmate-coach/fitmate-types';

const initialState: IWeekProgram = {
  weeks: [],
  currentPlanStatus: undefined,
  loading: true,
  isNutrition: false,
  currentWeekPlan: undefined,
  socialSupportIsInPlan: false,
  isSocialSupport: false,
};

export const planDocRef = (userId: string, planId: string) =>
  doc(db, 'users', userId, 'plans', planId);

export const plansCollectionRef = (userId: string) => collection(db, 'users', userId, 'plans');

export const fetchWeekProgram = createAsyncThunk(
  'weekProgram/fetchWeekProgram',
  async (userId: string) => {
    try {
      const q = query(plansCollectionRef(userId), orderBy('week', 'asc'));
      const querySnapshot = await getDocs(q);

      return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (e: any) {
      toast.error(e);
    }
  },
);

export const fetchCurrentProgramWeek = createAsyncThunk(
  'weekProgram/fetchCurrentProgramWeek',
  async (args: { userId: string; currentPlanId: string }, APIThunk) => {
    try {
      const dispatch = APIThunk.dispatch;
      const docRef = planDocRef(args.userId, args.currentPlanId);
      return onSnapshot(docRef, (snapshot) => {
        const data = { id: snapshot.id, ...snapshot.data() } as ProgramPlan;
        const { published } = data;
        if (published) {
          // check if the plan contains social support
          const socialSupport = published.plan.find(
            (focusArea) =>
              focusArea.title === 'Getting social support' && focusArea.disabled === false,
          );
          if (socialSupport) {
            // flag this user as having social support
            dispatch(markSocialSupportInPlan());
          }
        }
        dispatch(documentUpdated(data)); // dispatch an action to update the state
      });
    } catch (e: any) {
      console.log(e);
    }
  },
);

export const updateWeekProgram = createAsyncThunk(
  'weekProgram/updateWeekProgram',
  async (args: IUpdateWeekProgram, APIThunk) => {
    try {
      const { existingPlan, weeksInput, activePlanId, userId } = args;
      const { dispatch } = APIThunk;
      dispatch(loadingProgramWeek());
      dispatch(loadProgramPlan());
      for (const week in weeksInput) {
        if (existingPlan[week]) {
          await updateDoc(planDocRef(userId, existingPlan[week].id), {
            week: weeksInput[week].week,
            startDate: weeksInput[week].startDate,
            endDate: weeksInput[week].endDate,
          });
        } else {
          const q = query(plansCollectionRef(userId), orderBy('week', 'asc'));
          const querySnapshot = await getDocs(q);
          const plansRef = querySnapshot.docs.map((document) => {
            return planDocRef(userId, document.id).id;
          });

          // activePlan => displayedPlan not used for the moment, maybe in the future activePlan ? plansRef.indexOf(activePlan)
          const selectedPlan = plansRef.length - 1;

          if (weeksInput[selectedPlan].published) {
            toast.warning('Wait while the program week gets duplicated');
            await fetch(`${process.env.REACT_APP_BACKEND_API_ROOT_HOSTED}/clone-plan`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                userId,
                activePlanId,
                weeksInput,
                existingPlan,
              }),
            })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.error('Error when cloning program week:', error);
              })
              .finally(() => {
                dispatch(fetchWeekProgram(userId));
                toast.success('Program Week created successfully');
              });
          } else {
            toast.error("You can't add a new week without a published plan on current week.");
          }
        }
      }
      dispatch(fetchWeekProgram(userId));
    } catch (e: any) {
      console.log(e);
    }
  },
);

interface IPublishUpdatePlan {
  weekPlan: TFirePlanId;
  userId: string;
}

export const publishUpdatePlan = createAsyncThunk(
  'weekProgram/publishUpdatePlan',
  async (args: IPublishUpdatePlan, APIThunk) => {
    const dispatch = APIThunk.dispatch;
    const { weekPlan, userId } = args;
    await updateDoc(planDocRef(userId, weekPlan.id), {
      updatedAt: new Date(),
      draft: deleteField(),
      lastPublishedDate: Timestamp.now(),
    });
    if (weekPlan.draft) {
      await updateDoc(planDocRef(userId, weekPlan.id), {
        published: { ...weekPlan.draft },
      });
    }
    dispatch(fetchWeekProgram(userId));
  },
);

interface IUpdateCardNotes {
  focusArea: TFocusArea;
  currentWeekPlan: TFirePlanId;
  userId: string;
  notes: string;
}

export const updateCardNotes = createAsyncThunk(
  'weekProgram/updateNotes',
  async (args: IUpdateCardNotes) => {
    try {
      const { currentWeekPlan, focusArea, notes, userId } = args;
      const focusAreas = currentWeekPlan?.draft?.plan ?? currentWeekPlan?.published?.plan;
      const draftPlan: TFocusArea[] = [];

      if (focusAreas) {
        focusAreas.forEach((focusAreaMap: TFocusArea) => {
          draftPlan.push(focusAreaMap.id === focusArea.id ? { ...focusArea, notes } : focusAreaMap);
        });

        await updateDoc(planDocRef(userId, currentWeekPlan.id), {
          draft: { plan: draftPlan },
          updatedAt: Timestamp.now(),
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  },
);

interface IDisableCard {
  focusArea: TFocusArea;
  userId: string;
  currentWeekPlan: TFirePlanId;
}

export const disableCard = createAsyncThunk(
  'weekProgram/disableCard',
  async (args: IDisableCard) => {
    try {
      const { focusArea, userId, currentWeekPlan } = args;
      const focusAreas = currentWeekPlan?.draft?.plan ?? currentWeekPlan?.published?.plan;
      const draftPlan: TFocusArea[] = [];
      if (focusAreas) {
        focusAreas.forEach((focusAreaMap: TFocusArea) => {
          draftPlan.push(
            focusAreaMap.id === focusArea.id
              ? { ...focusArea, disabled: !focusArea.disabled }
              : focusAreaMap,
          );
        });

        await updateDoc(planDocRef(userId, currentWeekPlan.id), {
          draft: { plan: draftPlan },
          updatedAt: Timestamp.now(),
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  },
);

interface IReOrderCard {
  selectedPlan: string;
  next: TFocusArea[];
  userId: string;
}

export const reOrderCard = createAsyncThunk(
  'weekProgram/reOrderCard',
  async (args: IReOrderCard) => {
    try {
      const { selectedPlan, next, userId } = args;
      if (selectedPlan && next && userId) {
        await updateDoc(planDocRef(userId, selectedPlan), {
          draft: { plan: next },
          updatedAt: Timestamp.now(),
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  },
);

export const fetchCategory = createAsyncThunk(
  'weekProgram/fetchCategory',
  async (args: any, APIThunk: any) => {
    try {
      const { dispatch } = APIThunk;
      const programAction = APIThunk.getState().programActions.programAction;
      const focusAreas = APIThunk.getState().focusArea.focusAreas;
      const currentProgramWeek = APIThunk.getState().weekProgram.currentWeekPlan;
      const currentFocusArea = currentProgramWeek.published.plan.find(
        (area: FocusArea) => area.id === programAction.planAreaId,
      );
      const focusArea = focusAreas.find(
        (area: FocusArea) => area.handoutId === currentFocusArea.handoutId,
      );
      if (focusArea) {
        if (focusArea.name === 'Getting social support') {
          dispatch(setSocialSupportAction());
        }
      }
      const { categoryId } = args;
      if (!categoryId) {
        return Promise.reject(new Error('no category id'));
      }
      const docRef = doc(db, 'actions_categories', categoryId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        return Promise.reject(new Error('No such document!'));
      }
      return docSnap.data();
    } catch (e) {
      console.log('error in fetchCategory weekProgram', e);
      return Promise.reject(new Error('error in fetchCategory weekProgram'));
    }
  },
);

export const attachCategory = createAsyncThunk(
  'weekProgram/attachCategory',
  async (args: any, APIThunk: any) => {
    try {
      const { getState, dispatch } = APIThunk;
      const { programAction, focusAreas, currentWeekPlan } = args;
      const { customer } = getState().customerInfos;

      if (!programAction) {
        return Promise.reject(new Error('no programAction id'));
      }

      const { planId, planAreaId } = programAction;
      if (!planId || !planAreaId) {
        console.log('no planId or planAreaId', programAction.id);
        return Promise.reject(new Error('no planId or planAreaId'));
      }

      const currentFocusArea = currentWeekPlan.published.plan.find(
        (focusArea: TFocusArea) => focusArea.id === planAreaId,
      );
      if (!currentFocusArea) {
        console.log('currentFocusArea', currentFocusArea);
        toast.error('No focus area found');
      }
      const parentFocusArea = focusAreas.find(
        (focusArea: TFocusArea) => focusArea.handoutId === currentFocusArea.handoutId,
      );
      if (!parentFocusArea) {
        console.log('no parentFocusArea', parentFocusArea);
        toast.error('No parent focus area');
      }
      const categoriesQuery = await getDocs(collection(db, 'actions_categories'));
      const categories = categoriesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as any;
      const category = categories.find((category: any) =>
        parentFocusArea.category.includes(category.name.toLowerCase()),
      );
      if (category) {
        const programActionRef = doc(db, 'users', customer.id, 'programActions', programAction.id);
        await updateDoc(programActionRef, { categoryId: category.id });
        dispatch(listProgramActions(customer.id));
      }
    } catch (e) {
      console.log('error in fetchCategory weekProgram', e);
      return Promise.reject(new Error('error in fetchCategory weekProgram'));
    }
  },
);

export const weekProgramSlice = createSlice({
  name: 'weekProgram',
  initialState,
  reducers: {
    updateWeekProgram: (state, action: PayloadAction<any>) => {
      state.weeks = action.payload;
    },
    setCurrentPlanStatus: (state, action: PayloadAction<any>) => {
      state.currentPlanStatus = action.payload;
    },
    resetWeeks: (state) => {
      state.weeks = null;
      state.currentWeekPlan = undefined;
      state.isNutrition = false;
      state.currentPlanStatus = undefined;
      state.socialSupportIsInPlan = false;
      state.isSocialSupport = false;
    },
    documentUpdated: (state, action: PayloadAction<any>) => {
      state.currentWeekPlan = action.payload;
    },
    markSocialSupportInPlan: (state) => {
      state.socialSupportIsInPlan = true;
    },
    setSocialSupportAction: (state) => {
      state.isSocialSupport = true;
    },
    loadingProgramWeek: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeekProgram.pending, (state) => {
        state.weeks = null;
        state.loading = true;
      })
      .addCase(fetchWeekProgram.fulfilled, (state, action: PayloadAction<any>) => {
        state.weeks = action.payload;
        state.loading = false;
      })
      .addCase(fetchCategory.rejected, (state) => {
        state.weeks = [];
        state.loading = false;
      })
      .addCase(fetchCategory.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload) {
          const { name } = action.payload;
          state.isNutrition = name === 'Nutrition';
          state.isSocialSupport = name === '';
          state.category = action.payload;
        }
      });
  },
});

export const {
  setCurrentPlanStatus,
  resetWeeks,
  documentUpdated,
  markSocialSupportInPlan,
  setSocialSupportAction,
  loadingProgramWeek,
} = weekProgramSlice.actions;

export default weekProgramSlice.reducer;
