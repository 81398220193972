import React, { useState, useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { FiberManualRecord as CircleIcon } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PaidIcon from '@mui/icons-material/MonetizationOn';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import MenuIcon from '@mui/icons-material/MoreVert';
import { getUserRef } from '@/api/firestores';
import { useAppSelector } from '@/hooks';
import { useSetChatReadStatus } from '@/hooks/chat/useChatThreadUnRead';
import { getClientUserStatus } from '@/utils/data/user';
import { formatDateToList } from '@/utils/format/datetime';
import {
  BadgeWatched,
  BageCircleBlock,
  BottomBlock,
  BottomBlockLine,
  CancelledIcon,
  CentralBlock,
  Container,
  ContainerWatched,
  ContentBlock,
  DateText,
  LeftBlock,
  MenuBlock,
  MessageBlock,
  MessageText,
  NameBlock,
  StyledImgIcon,
  StyledMenuIcon,
  SubMenu,
  SubMenuIcon,
  UserNameText,
} from './styles';
import { EPaymentStatusValue, TClientSearchUser } from '@/types';
import TrialIcon from 'assets/icons/trial.svg';
import { ChatClientCard } from '../ChatClientCard';
import Menu from '@/common/core/Menu';
import { Link } from 'react-router-dom';
import { ClientUser } from '@fitmate-coach/fitmate-types';
import { Timestamp } from 'firebase/firestore';

const ChatThreadsListItem = (props: { user: TClientSearchUser }) => {
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const { coaches } = useAppSelector((state) => state.watcher);
  const { coaches: coachesList } = useAppSelector((state) => state.coaches);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isCustomerWatchedAlready, setIsCustomerWatchedAlready] = useState<boolean>(false);
  const [firstCoachWatching, setFirstCoachWatching] = useState<string>('');
  const [numberOfCoachWatching, setNumberOfCoachWatching] = useState<number>(0);
  const setChatReadStatus = useSetChatReadStatus();
  const [userFb] = useDocumentData(getUserRef(props.user.id));
  const userId = props.user.id;
  const user = (userFb || props.user) as ClientUser;
  const { status, hoursSinceAnswerRequired } = getClientUserStatus(user as ClientUser);

  useEffect(() => {
    if (userId && coaches) {
      const coachesOnCustomer = Object.keys(coaches).filter((coachId: any) => {
        return coaches[coachId] === userId && coachId !== coachData?.id;
      });
      const firstCoach = coachesOnCustomer[0];
      const coachInfos = coachesList.find((coach) => coach.id === firstCoach);
      setIsCustomerWatchedAlready(coachesOnCustomer.length > 0);
      setFirstCoachWatching(
        `${coachInfos?.firstName?.slice(0, 1).toUpperCase()}${coachInfos?.lastName
          ?.slice(0, 1)
          .toUpperCase()}`,
      );
      setNumberOfCoachWatching(coachesOnCustomer.length);
    } else {
      setIsCustomerWatchedAlready(false);
      setFirstCoachWatching('');
      setNumberOfCoachWatching(0);
    }
  }, [coaches, userId, coachesList]);

  const toggleMenu = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    e.stopPropagation();
    return anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget);
  };

  return (
    <>
      <Container>
        <ContentBlock>
          <LeftBlock>
            <Link to={`/coach-dashboard/client/${userId}`}>
              <ChatClientCard
                name={user.firstName + ' ' + user.lastName}
                status={status}
                timezone={user.timezone}
                night={status === 'night'}
                hours={user.lastMessage?.answerRequired ? `${hoursSinceAnswerRequired}h` : ''}
                isLive={!!props.user.isLive}
                isEow={!!props.user.isEow}
                isOnboarding={!!props.user.isOnboarding}
                haveUnreadMessages={user.lastMessage?.answerRequired}
                isEngagementMessage={user.lastMessage?.isEngagementMessage}
                haveReminders={user.hasUpcomingReminder}
              />
            </Link>
          </LeftBlock>
          <Link to={`/coach-dashboard/client/${userId}`}>
            <CentralBlock>
              <NameBlock>
                <UserNameText noWrap>
                  {user.firstName} {user.lastName}
                </UserNameText>

                {user.billingData?.status === EPaymentStatusValue.TRIAL && (
                  <StyledImgIcon src={TrialIcon} alt="" />
                )}
                {user.billingData?.status === EPaymentStatusValue.PAID && (
                  <StyledImgIcon as={PaidIcon} />
                )}
                {user.billingData?.status === EPaymentStatusValue.CANCELLED && (
                  <CancelledIcon as={MoneyOffIcon} />
                )}
              </NameBlock>
              <MessageBlock>
                <MessageText as="div">
                  <ReactMarkdown
                    children={user.lastMessage?.body ?? ''}
                    components={{
                      a: ({ ...props }) => <span {...props} />,
                      p: 'span',
                    }}
                    remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                  />
                </MessageText>
              </MessageBlock>
              <DateText noWrap>
                {user.lastMessage &&
                  user.lastMessage.createdAt &&
                  formatDateToList(
                    user.lastMessage.createdAt instanceof Timestamp
                      ? user.lastMessage.createdAt.seconds
                      : 0,
                  )}
              </DateText>
            </CentralBlock>
          </Link>
          <MenuBlock onClick={toggleMenu} onKeyDown={toggleMenu} role="button">
            <StyledMenuIcon as={MenuIcon} />
          </MenuBlock>
          <BageCircleBlock>{user.lastMessage?.answerRequired && <CircleIcon />}</BageCircleBlock>
        </ContentBlock>
        <BottomBlock>
          <BottomBlockLine />
        </BottomBlock>
        {isCustomerWatchedAlready && (
          <Link to={`/coach-dashboard/client/${userId}`}>
            <ContainerWatched>
              <BadgeWatched
                addExtraMargin={numberOfCoachWatching < 2}
                label={`${firstCoachWatching} ${
                  numberOfCoachWatching > 1 ? ' + ' + numberOfCoachWatching : ''
                }`}
              />
            </ContainerWatched>
          </Link>
        )}
      </Container>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <SubMenu onClick={() => setChatReadStatus(userId, user.isMessagesUnRead!)}>
          {user.isMessagesUnRead ? ' Mark as Read' : 'Mark as Unread'}
          <SubMenuIcon as={VisibilityIcon} />
        </SubMenu>
      </Menu>
    </>
  );
};

export default ChatThreadsListItem;
