import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  doc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '@/utils/firebase/firebaseInit';
import { Reminder } from '@/types/reminders';
import { subDays } from 'date-fns';
import { Message } from '@fitmate-coach/fitmate-types';

interface IRemindersState {
  loading: boolean;
  reminders: Reminder[];
}

const initialState: IRemindersState = {
  loading: true,
  reminders: [],
};

export const fetchUserReminders = createAsyncThunk(
  'reminders/fetchUserReminders',
  async (userId: string) => {
    try {
      const remindersRef = collection(db, 'users', userId, 'reminders');
      const threeDaysAgo = Timestamp.fromDate(subDays(new Date(), 3));

      const q = query(remindersRef, where('schedule.sendAtUtc', '>=', threeDaysAgo));
      const querySnapshot = await getDocs(q);

      return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as Reminder[];
    } catch (error) {
      console.error('Error fetching reminders:', error);
      return [] as Reminder[];
    }
  },
);

export const deleteReminder = createAsyncThunk(
  'reminders/deleteReminder',
  async ({ userId, reminderId }: { userId: string; reminderId: string }) => {
    try {
      await deleteDoc(doc(db, 'users', userId, 'reminders', reminderId));
      return reminderId;
    } catch (error) {
      console.error('Error deleting reminder:', error);
      throw error;
    }
  },
);

export const updateReminder = createAsyncThunk(
  'reminders/updateReminder',
  async ({
    userId,
    reminderId,
    updates,
  }: {
    userId: string;
    reminderId: string;
    updates: Partial<Reminder>;
  }) => {
    try {
      const reminderRef = doc(db, 'users', userId, 'reminders', reminderId);

      // Clean up the schedule object if it exists
      if (updates.schedule) {
        const { frequency, ...restSchedule } = updates.schedule;
        updates = {
          ...updates,
          schedule: restSchedule,
        };
      }

      await updateDoc(reminderRef, updates);
      return { id: reminderId, ...updates };
    } catch (error) {
      console.error('Error updating reminder:', error);
      throw error;
    }
  },
);

export const remindersSlice = createSlice({
  name: 'reminders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserReminders.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserReminders.fulfilled, (state, action) => {
        state.loading = false;
        state.reminders = action.payload;
      })
      .addCase(fetchUserReminders.rejected, (state) => {
        state.loading = false;
        state.reminders = [];
      })
      .addCase(deleteReminder.fulfilled, (state, action) => {
        state.reminders = state.reminders.filter((reminder) => reminder.id !== action.payload);
      })
      .addCase(updateReminder.fulfilled, (state, action) => {
        const index = state.reminders.findIndex((r) => r.id === action.payload.id);
        if (index !== -1) {
          state.reminders[index] = { ...state.reminders[index], ...action.payload };
        }
      });
  },
});

export default remindersSlice.reducer;
