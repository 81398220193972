import { Timestamp } from '@firebase/firestore';
import { TFireBaseUser, TClientSearchUserRaw, TDeepPartial } from '@/types';

export const remapData = (data: TClientSearchUserRaw[]) => {
  return data.map((item) => {
    return {
      id: item.id.raw,
      role: item?.role?.raw as TFireBaseUser['role'] | undefined,
      email: item?.email?.raw,
      firstName: item?.firstname?.raw,
      lastName: item?.lastname?.raw,
      timezone: item?.timezone?.raw,
      createdAt: timestamp(item.createdat?.raw),
      isMessagesUnRead: bool(item.ismessagesunread?.raw),
      lastSeenAt: timestamp(item.lastseenat?.raw),
      billingData: {
        status: item.billingdata__status?.raw as any,
        isPaid: bool(item.billingdata__status?.raw),
        isReferralLinkEnabled: bool(item.billingdata__isreferrallinkenabled?.raw),
      },
      lastMessage: {
        answerRequired: timestamp(item?.lastmessage__answerrequired?.raw),
        isCustomerMsg: bool(item.lastmessage__iscustomermsg?.raw),
        createdAt: timestamp(item.lastmessage__createdat?.raw),
        body: item.lastmessage__body?.raw,
      },
      coachAssignment: {
        hasCoachesAssigned: bool(item.coachassignment__hascoachesassigned?.raw),
      },
      copilotFlow: item.hasactivesession?.raw,
      isLive: bool(item.hasactivesession?.raw),
      isOnboarding: bool(item.isonboarding?.raw),
      isEow: bool(item.iseow?.raw),
      haveReminders: bool(item.hasupcomingreminder?.raw),
    } satisfies TDeepPartial<TFireBaseUser> & {
      id: string;
      isLive: boolean | undefined;
      isOnboarding: boolean | undefined;
      isEow: boolean | undefined;
      haveReminders: boolean | undefined;
    };
  });
};

const bool = (x?: string) => (x === 'false' ? false : x === 'true' ? true : undefined);
const timestamp = (x?: string) => {
  if (!x) return undefined;
  try {
    const parsed = JSON.parse(x);
    if (parsed._seconds) return Timestamp.fromDate(new Date(parsed._seconds * 1000));
    throw '';
  } catch (e) {
    return Timestamp.fromDate(new Date(x));
  }
};
