import { Timestamp } from 'firebase/firestore';

export enum ReminderStatus {
  active = 'active',
  inactive = 'inactive',
  completed = 'completed',
  missed = 'missed',
  rejected = 'rejected',
}

export enum ReminderTypes {
  firstDay = 'firstDay',
  midWeek = 'midWeek',
  endWeek = 'endWeek',
  notable = 'notable',
  company = 'company',
  custom = 'custom',
  behind = 'behind',
}

export interface ReminderSchedule {
  days?: number[];
  time?: string;
  sendAtUtc?: Timestamp;
  nickname: 'morning' | 'midday' | 'early_evening' | 'afternoon';
  repeating: boolean;
  frequency?: 'daily' | 'weekly' | 'monthly';
  needEndDate: boolean;
  weeklyDays?: number[];
}

export interface Reminder {
  id?: string;
  name: string;
  status: ReminderStatus;
  target: string;
  schedule: ReminderSchedule;
  channel: string[];
  content: string;
  needApproval: boolean;
  sender: 'coach' | 'system';
  programWeek: number;
  reminderType: ReminderTypes;
  goals?: string[];
  timezone: string;
}
